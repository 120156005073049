<template>
  <div>
    <h1 class="heading color-primary heading--bigger mb-8">
      mój monitoring UE
    </h1>

    <item-nav
      only-other
      filter-other
      acts="ue"
      noActs="noUe"
      notMonitored="UE_NOT_MONITORED"
      map="UE_MAP"
      class="mb-4 mb-tablet-8"
    />

    <router-view />
  </div>
</template>

<script>
import ItemNav from '../../components/ItemNav';

export default {
  components: {
    ItemNav,
  },
};
</script>
